<template>
    <section class="content" style="height: 100% !important;background: linear-gradient(90deg, #F0F0F0 0%, #F8F8F8 100%)">
        <aside class="main-sidebar sidebar-light-olive">
            <div class="box-title" style="text-align: -webkit-center;background: white;">
              <router-link to="/" class="logo-height">
                <img src="@/assets/img/logo-text2.png" alt="AdminLTE Logo" class="wide logo-height" style="width: 100%;height: auto;position: relative;top: 50%;transform: translateY(-50%);">
                <img src="@/assets/img/logo-no-text.png" alt="AdminLTE Logo" class="lite logo-height" style="opacity: 0.8;"></router-link>
            </div>
        </aside>
        <nav id="sidemenu" class="main-header navbar navbar-expand navbar-white navbar-light">
            <ul class="navbar-nav ml-auto">
              <li class="hide nav-item">
                <span class="nav-link" style="padding-right:2px;">
                  <div class="image d-inline-block ml-1">
                    <img :src="imgUrl" class="image-header img-circle" alt="User Image"/>
                  </div>
                </span>
              </li>
              <li class="nav-item">
                <router-link to="/profile" class="nav-link" style="padding-left:0;">{{ name }}</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" v-on:click="signOut"><i class="fas fa-sign-out-alt"></i></a>
              </li>
            </ul>
        </nav>
        <div class="custom-container">
            <div class="row">
                <div class="col-sm-8 left-side">
                    <div class="box-top shadow row">
                        <div class="col-sm-8">
                            <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                            <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                        </div>
                        <div class="col-sm-4" style="text-align-last: end;">
                            <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4" v-if="akademik">
                            <div class="shadow items">
                                <router-link to="/dashboard-akademik">
                                <div class="items-circle">
                                    <img src="@/assets/img/Vector.png" class="items-icon" alt="Akademik">
                                </div>
                                <div class="items-title">Akademik</div>
                                <div class="items-desc" style="color: black;">
                                    Pencatatan transaksi keuangan seputar kas dan bank
                                </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="administrasi">
                            <div class="shadow items">
                                <router-link to="/dashboard-administrasi">
                                <div class="items-circle" style="background: linear-gradient(90deg, rgba(240, 152, 25, 0.6) 0%, rgba(237, 222, 93, 0.6) 100%);">
                                    <img src="@/assets/img/clarity_computer-solid.png" class="items-icon" alt="Akademik">
                                </div>
                                <div class="items-title">Administrasi</div>
                                <div class="items-desc" style="color:black;">
                                    Pencatatan transaksi keuangan seputar kas dan bank
                                </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="pr">
                            <div class="shadow items">
                                <router-link to="/dashboard-pr">
                                <div class="items-circle" style="background: linear-gradient(90deg, rgba(236, 0, 140, 0.6) 0%, rgba(252, 103, 103, 0.6) 100%);">
                                    <img src="@/assets/img/bi_megaphone.png" class="items-icon" alt="Akademik">
                                </div>
                                <div class="items-title">Public Relation</div>
                                <div class="items-desc" style="color: black;">
                                    Pencatatan transaksi keuangan seputar kas dan bank
                                </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="finance">
                            <div class="shadow items">
                                <router-link to="/dashboard-keuangan">
                                <div class="items-circle" style="background: linear-gradient(90deg, rgba(97, 144, 232, 0.8) 0%, rgba(167, 191, 232, 0.8) 100%);">
                                    <img src="@/assets/img/la_money-bill-wave-alt.png" class="items-icon" alt="Akademik">
                                </div>
                                <div class="items-title">Keuangan</div>
                                <div class="items-desc" style="color:black;">
                                    Pencatatan transaksi keuangan seputar kas dan bank
                                </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="superuser">
                            <div class="shadow items">
                                <router-link to="/dashboard-manajemen">
                                <div class="items-circle" style="background: linear-gradient(90deg, rgba(133, 140, 140, 0.75) 0%, rgba(222, 203, 164, 0.75) 100%);">
                                    <img src="@/assets/img/fluent_organization-16-regular.png" class="items-icon" alt="Akademik">
                                </div>
                                <div class="items-title">Manajemen</div>
                                <div class="items-desc" style="color:black;">
                                    Pencatatan transaksi keuangan seputar kas dan bank
                                </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="superuser">
                            <div class="shadow items">
                                <router-link to="/dashboard-sistem">
                                <div class="items-circle" style="background: linear-gradient(90deg, rgba(255,147,52,0.75) 35%, rgba(255,181,111,0.75) 100%);">
                                    <img src="@/assets/img/gear2.png" class="items-icon" alt="Akademik" style="margin-top: 5px;">
                                </div>
                                <div class="items-title">System</div>
                                <div class="items-desc" style="color:black;">
                                    Pengaturan Aplikasi
                                </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" style="padding-left: 24px;">
                    <div class="right-side">
                        <div class="row" style="min-height: 95px;background-color: white;padding:4px">
                            <div class="col-sm-3 col-md-4 col-xs-12 col-4" style="align-self: center;padding-left: 24px;">
                                <img :src="imgUrl" class="" alt="Akademik" style="border-radius: 50%;width: 64px;height: 64px">
                            </div>
                            <div class="col-sm-6 col-8" style="align-self: center;">
                                <div class="row" style="padding-left: 12px;margin-bottom: 8px; font-size: 14px">Halo, <b>{{ name }}</b></div>
                                <div class="row" style="padding-left: 4px;">
                                    <div class="col-sm-6 col-md-12 col-lg-6">Akademik</div>
                                    <div class="col-sm-6 col-md-12 col-lg-6">HO Surabaya</div>
                                </div>
                            </div>
                            <div class="col-sm-4 row" style="padding-left: 12px;align-items: center;display:none">
                                <div class="action">
                                    <IconifyIcon :icon="icons.pencilSolid" :style="{fontSize: '18px', transform: 'translateY(50%)'}" />
                                </div>
                                <div class="action">
                                    <IconifyIcon :icon="icons.history24Filled" :style="{fontSize: '18px', transform: 'translateY(50%)'}" />
                                </div>
                                <div class="action">
                                    <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '18px', transform: 'translateY(50%)'}" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div id="myCal"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <footer class="main-footer" style="margin-left:0px">
            <div class="float-right d-none d-sm-block">
              <b>Version</b> 1.1
            </div>
            <strong>Copyright &copy; 2021 <a href="http://tarunacendekia.com">tarunacendekia.com</a>.</strong> All rights
            reserved.
          </footer>
    </section>
</template>
<script>
import {auth} from '@/libs/hxcore';
import IconifyIcon from '@iconify/vue';
import pencilSolid from '@iconify/icons-clarity/pencil-solid';
import history24Filled from '@iconify/icons-fluent/history-24-filled';
import activityFill from '@iconify/icons-eva/activity-fill';
import Calendar from 'color-calendar';
import "color-calendar/dist/css/theme-glass.css";

export default {
    name: 'Home',
    components: {
        IconifyIcon
    },
    data() {
        return {
            icons: {
                pencilSolid,
                history24Filled,
                activityFill
            },
            name : '',
            imgUrl : '',
            administrasi: false,
            pr: false,
            akademik: false,
            finance: false,
            superuser:false
        };
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        var info = auth.user();

        if(info)
        {
          this.name = info.display_name;
          this.imgUrl = info.img_url;
          if (info.role_id==3) {
            this.administrasi = true;
          } else if (info.role_id==12) {
            this.pr = true;
            this.akademik = true;
          } else if (info.role_id==10) {
            this.akademik = true;
            this.administrasi = true;
            this.pr = true;
          } else if (info.role_id==11) {
            this.finance = true;
            this.administrasi = true;
            this.akademik = true;
          }
          else if(info.role_id==1) {
            this.administrasi = true;
            this.pr = true;
            this.superuser = true; 
            this.akademik = true;
            this.finance = true;
          }
        }
        else{
          this.imgUrl = '/dist/img/user.jpeg';
        }
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/61283dd7d6e7610a49b2375d/1fe2j35je';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
          })();
    },
    methods: {
        signOut: function(e) {
          console.log('signout')
          auth.logout();
          this.$router.push('/login');
          e.preventDefault();
        }
      },
    mounted() {
        new Calendar({
          id: "#myCal",
          theme: "glass",
          eventsData: [
                {
                    start: '2021-06-17T06:00:00',
                    end: '2021-06-18T20:30:00',
                    name: 'Blockchain 101'
                },
            ]
        });
    }
}
</script>
<style type="text/css" scoped>
    html {
        height: 100% !important;
    }
    .main-sidebar{
        height: initial;
        min-height: initial;
    }

    #app {
        height: 100% !important;
    }

    .left-side {
        /*padding-right: 24px;*/
    }

    .box-top {
        min-height: 121px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        
        padding-left: 31px;
        min-height: 36px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 48px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .custom-container {
        padding: 16px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 20px;
        padding-top: 16px;
        margin-bottom: 16px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 170px;
    }

    .items-circle {
        width: 56px;
        height: 56px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 8px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        width: 28px;
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    /*right side*/
    .right-side {
        background: linear-gradient(90deg, #E0EAFC 0%, #CFDEF3 100%);
        border-radius: 10px;
        padding: 20px 19px 0px 22px;
        height: 100%;
    }

    .action {
        width: 33px;
        height: 33px;
        background:#D2E0F5;
        margin-right:5px;
        border-radius: 2px;
        text-align-last: center;
    }

    #myCal {
        margin-top: 20px;
        margin-bottom: 30px;
        width: 100%;
        text-align-last: center;
    }

</style>